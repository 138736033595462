<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Ajouter un nouvel auteur</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 700px;"
      >
        <v-row :no-gutters="withAddresses">
          <v-col
            cols="12"
            :md="withAddresses?'7':'12'"
          >
            <v-card
              :outlined="withAddresses"
              elevation="0"
              class="fill-height"
            >
              <v-card-title v-if="withAddresses">
                {{ $t("Détails") }}
              </v-card-title>
              <v-divider v-if="withAddresses" />
              <v-card-text :class="{'p-0': !withAddresses }">
                <v-row :class="{'pt-5': withAddresses }">
                  <v-col
                    cols="12"
                  >
                    <div
                      class="me-3 "
                      style="width: 100px"
                    >
                      <span>{{ $t("Photo") }}</span>
                      <file-upload
                        v-model="item.avatar"
                        add-class="mx-auto justify-center"
                        :label="$t('Upload new photo')"
                        folder="images/users/avatars/"
                        :btn="true"
                        :extra="false"
                        :hide-label="false"
                        icon-name="mdi-pencil"
                        :icon="false"
                        :show-download="false"
                        inputname="file"
                        :crop="true"
                        :aspect-ratio="1"
                        :max-crop-height="300"
                        :max-crop-width="300"
                        accept="image/*"
                      >
                        <template #input>
                          <v-avatar
                            :color="item.avatar ? '' : 'primary'"
                            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                            size="100"
                            rounded
                            class="mb-4"
                          >
                            <v-img
                              v-if="item.avatar"
                              :lazy-src="require(`@/assets/images/avatars/1.png`)"
                              :src="item.avatar || require(`@/assets/images/avatars/1.png`)"
                            ></v-img>
                            <span
                              v-else
                              class="font-weight-semibold text-5xl"
                            >{{ avatarText(item.full_name) }}</span>
                            <v-icon
                              size="20"
                              color="primary"
                              style="position: absolute;bottom: 10px; left: 10px"
                            >
                              {{ icons.mdiCloudUploadOutline }}
                            </v-icon>
                          </v-avatar>
                        </template>
                      </file-upload>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="item.first_name"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :error-messages="errorMessages.first_name"
                      :label="$t('Prenom')"
                      :placeholder="$t('Prenom')"
                      :hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="item.last_name"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :error-messages="errorMessages.last_name"
                      :label="$t('Nom')"
                      :placeholder="$t('Nom')"
                      :hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                  >
<!--                    <v-combobox
                      v-model="item.email"
                      :rules="[validators.required, validators.emailValidator]"-->
                    <v-combobox
                      v-model="item.email"
                      outlined
                      type="email"
                      :label="$t('Email')"
                      :placeholder="$t('Email')"
                      :error-messages="errorMessages.email"
                      dense
                      :hide-details="true"
                      multiple
                      small-chips
                      clearable
                      clearable-chips
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item }}
                          </span>
                          <v-icon
                            small
                            @click="parent.selectItem(item)"
                          >
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.phone"
                      outlined
                      dense
                      :label="$t('Téléphone')"
                      :placeholder="$t('Téléphone')"
                      :error-messages="errorMessages.phone"
                      :hide-details="true"
                    ></v-text-field>
                  </v-col>

                                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-model="item.country_id"
                      :label="$t('Pays')"
                      :items="$store.state.app.pays"
                      item-text="full_name"
                      item-value="id"
                      outlined
                      dense
                      :hide-details="true"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.compte_auteur"
                      outlined
                      dense
                      :label="$t('Compte auteur')"
                      :placeholder="$t('Compte auteur')"
                      :error-messages="errorMessages.compte_auteur"
                      :hide-details="true"
                    ></v-text-field>
                  </v-col>
                  <!--                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-model="item.source_id"
                      :label="$t('Source')"
                      item-text="source_name"
                      item-value="id"
                      outlined
                      dense
                      hide-details="auto"
                      :items="sources"
                      :loading="isLoadingSources"

                      :search-input.sync="search"
                      no-data-text="Aucun élément disponible"
                    >
                    </v-autocomplete>
                  </v-col>-->
<!--                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-model="item.source_id"
                      :label="$t('Source')"
                      :items="$store.state['app'].sources"
                      item-text="source_name"
                      item-value="id"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>-->
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-switch
                        :hide-details="true"
                        :label="$t('Auteur mineur')"
                        v-model="item.minor_author"
                        :true-value="1"
                        :false-value="0"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-switch
                        :hide-details="true"
                        :label="$t('Cas particulier')"
                        v-model="item.particular_case"
                        true-value="yes"
                        false-value="no"
                      ></v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-autocomplete
                        v-model="item.profession_id"
                        :label="$t('Profession')"
                        :items="$store.state.app.professions"
                        required
                        item-text="label"
                        item-value="id"
                        outlined
                        dense
                        :hide-details="true"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>


<!--                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="item.status_id"
                      :label="$t('Statut')"
                      :items="$store.state['app'].statuses"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-select>
                  </v-col>-->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="withAddresses"
            cols="12"
            :md="withAddresses ?'5':'12'"
          >
            <addresses v-model="item.addresses" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="resetItem"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCloudUploadOutline,
} from '@mdi/js'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useAuthorsList from './useAuthorsList'
import FileUpload from '@/components/file_upload'
import Addresses from '@/views/apps/author/author-list/Addresses'
import authorStoreModule from '@/views/apps/author/authorStoreModule'

export default {
  components: { Addresses, FileUpload },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withAddresses: {
      type: Boolean,
      default: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    payOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-author'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = useAuthorsList()
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const loadingUpload = ref(false)
    const isLoadingSources = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}
      // resetForm()

       emit('update:is-form-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        console.log('saving authoer', item.value)
        store.dispatch('app-author/addAuthor', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            //resetItem()
            emit('object', response.data)
            emit('refresh-data')
            // emit('update:is-form-active', false)

            emit('refetch-author', response.data)
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    store
      .dispatch('app/fetchCountries', { per_page: 500 })
    store
      .dispatch('app/fetchSources', { per_page: 500 })
    store
      .dispatch('app/fetchStatuses', { per_page: 500, status_type: 'author' })

    return {
      resetItem,
      form,
      isLoadingSources,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      loadingUpload,
      onSubmit,
      countries,
      item,
      valid,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
